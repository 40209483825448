@font-face {
    font-family: 'airwavesregular';
    src: url('airwaves-webfont.woff2') format('woff2'),
         url('airwaves-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'josefin_sanssemibold';
    src: url('josefinsans-semibold-webfont.woff2') format('woff2'),
         url('josefinsans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'heatwaveregular';
    src: url('heatwave-webfont.woff2') format('woff2'),
         url('heatwave-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}